<!--
 * @Author: xiaohe
 * @LastEditors: xiaohe
-->
<template>
  <div class="container">
    <PlateTitle title="基本户转账">
      <template v-slot:extra>
        <p class="money">
          保费金额（元）：<span>￥{{ infoJson.userAcctInfo.sumPremium }}</span>
        </p>
      </template>
    </PlateTitle>
    <div class="info_mation">
      <div class="list_p">
        <div class="text_lable">企业基本户名称：</div>
        <div class="text">{{ infoJson.userAcctInfo.basicAccountName }}</div>
      </div>
      <div class="list_p">
        <div class="text_lable">企业基本户账号：</div>
        <div class="text">{{ infoJson.userAcctInfo.basicAccountNo }}</div>
      </div>
    </div>

    <PlateTitle title="收款信息" />
    <div class="table_list">
      <a-table
        bordered
        :pagination="false"
        :columns="columns"
        :data-source="data"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
      </a-table>
    </div>
    <div style="color:red">
      <p>注:</p>
      <p>1.支付前请核对基本户名称和基本户账户信息是否正确:</p>
      <p>2.保费支付，请确保使用基本户账户打款;</p>
      3.非基本户支付或打款金额与订单不一致，费用将会原路退回(金额错误请联系客服电话0575-88620523):</p>
    </div>
    <!-- <PlateTitle title="选择支付方式" /> -->
    <div class="code">
      <div class="code_img"></div>
    </div>

    <div class="footer">
      <a-button class="btn" type="primary" @click="goTo">
        已支付，返回
      </a-button>
    </div>
    <div></div>
  </div>
</template>
<script>
import PlateTitle from "@/components/PlateTitle";
import { cmbSubList } from "@/api/pay.js";
const columns = [
  {
    title: "收款开户名称",
    dataIndex: "guaranteeTypeStr",
    key: "guaranteeTypeStr"
  },
  {
    title: "开户账号",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "开户行",
    dataIndex: "receiveBank",
    key: "receiveBank"
  },
  {
    title: "金额（元）",
    dataIndex: "sumPremium",
    key: "sumPremium",
    scopedSlots: { customRender: "sumPremium" }
  }
];

const data = [];
export default {
  components: { PlateTitle },
  data() {
    return {
      data,
      columns,
      orderNo: "",
      infoJson: {},
      detailJson: {}
    };
  },
  mounted() {
    this.getInformation();
    this.orderNo = this.getQueryString("orderNo");
  },
  methods: {
    goTo() {
      this.$router.push({ path: "/insure", query: { orderNo: this.orderNo } });
    },
    getQueryString(name) {
      const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      const r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getInformation() {
      this.data = [];
      let encrypted = this.getQueryString("encrypted");
      cmbSubList(encrypted).then(res => {
        this.infoJson = res;
        this.data.push({
          guaranteeTypeStr: res.accountInfo.receiveAcctName,
          name: res.accountInfo.receiveAcct + res.accountInfo.subaccount,
          receiveBank: res.accountInfo.receiveBank,
          sumPremium: res.userAcctInfo.sumPremium
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  min-height: 726px;
  margin-top: 30px;
  position: relative;
  padding: 50px 30px;
  .money {
    font-size: 14px;
    font-weight: 500;
    span {
      font-size: 22px;
      color: #da0c25;
      font-weight: bold;
    }
  }
  .info_mation {
    padding-left: 40px;
    margin-bottom: 30px;
    .list_p {
      display: flex;
      margin-bottom: 10px;
      .text_lable {
        width: 300px;
        font-size: 16px;
        color: #a0a0a0;
      }
      .text {
        font-weight: 600;
        color: #545454;
      }
    }
  }
  .table_list {
    margin: 30px;
  }

  .code {
    width: 100%;

    .code_img {
      width: 230px;
      // height: 50px;
      // background: red;
      margin: auto;
      margin-top: 100px;
      margin-bottom: 10px;
    }

    p {
      text-align: center;
    }
  }

  .footer {
    border-top: 2px solid #d7d7d7;
    width: 100%;
    text-align: right;
    margin-top: 40px;
    padding-top: 30px;
  }
  .btn_list {
    width: 200px;
    height: 45px;
    color: white;
    background: #40a9ff;
    border: none;
    cursor: pointer;
  }
}
</style>
